import {
  RESET_STATE,
  SET_EVENT_TYPE,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MATCH_ODDS,
} from './exchangeSportsActionTypes';

import { ExchangeSportsState } from '../../models/ExchangeSportsState';
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import { ExchangePriceDTO } from '../../models/common/ExchangePriceDTO';
import { IPL } from '../../constants/IPLEvent';

type Action = {
  type: string;
  payload: any;
};

const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: 'Binary', name: 'Binary', slug: 'binary' },
];

const initialState: ExchangeSportsState = {
  eventTypes: EXCHANGE_EVENT_TYPES,
  selectedEventType: { id: '4', name: 'Cricket', slug: 'cricket' },
  competitions: {},
  selectedCompetition: { id: '', name: '', slug: '' },
  events: {},
  selectedEvent: { id: '', name: '', slug: '' },
  secondaryMatchOddsMap: {},
  secondaryMarketsMap: {},
  scorecard: null,
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  for (let i = 0; i < 3; i += 1) {
    if (prices[i]) pricesSet.push(prices[i]);
    else pricesSet.push({ price: null, size: null });
  }
  return pricesSet;
};

const exchangeSportsReducer = (
  state = initialState,
  action: Action
): ExchangeSportsState => {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...state,
        eventTypes: [],
        selectedEventType: { id: '1', name: 'Football', slug: 'football' },
        competitions: {},
        selectedCompetition: { id: '', name: '', slug: '' },
        events: {},
      };
    }

    case SET_EVENT_TYPE: {
      return {
        ...state,
        selectedEventType: action.payload,
      };
    }

    case SET_COMPETITION: {
      return {
        ...state,
        selectedCompetition: action.payload,
      };
    }

    case FETCH_EVENT_BY_COMPETITION_SUCCESS: {
      const eId: string = action.payload.sportId;
      const cId: string = action.payload.competitionId;
      const eventId: string = action.payload.eventId;

      // Add events map hierarchy
      const allEvents = { ...state.events };
      if (!allEvents[eId]) {
        allEvents[eId] = {};
      }
      if (!allEvents[eId][cId]) {
        allEvents[eId][cId] = {};
      }

      // Add events data
      if (action.payload.eventData) {
        let eData;
        if (eventId && allEvents[eId][cId][eventId])
          eData = { ...allEvents[eId][cId][eventId] };
        else eData = { ...action.payload.eventData };

        let homeTeam = eData.homeTeam ? eData.homeTeam : '';
        let awayTeam = eData.awayTeam ? eData.awayTeam : '';
        if (
          homeTeam === '' &&
          awayTeam === '' &&
          eData.eventName?.toLowerCase().includes(' v ')
        ) {
          homeTeam = eData.eventName.split(' v ')[0].trim();
          awayTeam = eData.eventName.split(' v ')[1].trim().split(' - ')[0];
        }

        // Set MatchOdds Data.
        const matchOddsData = action.payload.matchOddsData;
        const runners: MatchOddsRunnerDTO[] = [];
        if (matchOddsData) {
          if (
            matchOddsData.matchOddsRunners &&
            matchOddsData.matchOddsRunners.length > 0
          ) {
            let i = 0;
            for (let e of matchOddsData.matchOddsRunners) {
              if (e) {
                const runnerName = e.runnerName ? e.runnerName : e.RunnerName;

                if (
                  i === 0 &&
                  !runnerName.toLowerCase().includes('draw') &&
                  runnerName !== awayTeam
                ) {
                  homeTeam = runnerName;
                }

                if (
                  i !== 0 &&
                  !runnerName.toLowerCase().includes('draw') &&
                  runnerName !== homeTeam
                ) {
                  awayTeam = runnerName;
                }
                i += 1;
                runners.push({
                  selectionId: e.selectionId ? e.selectionId : e.SelectionId,
                  runnerName: runnerName,
                  backPrices: getMatchOddsSet(e.backPrices),
                  layPrices: getMatchOddsSet(e.layPrices),
                  status: e.status,
                });
              }
            }
          }
          eData.matchOdds = {
            marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
            marketName: matchOddsData.marketName
              ? matchOddsData.marketName
              : '',
            inplay: matchOddsData.inplay ? matchOddsData.inplay : false,
            status: matchOddsData.status ? matchOddsData.status : '',
            matchOddsRunners: runners,
          };
        } else {
          let matchOdds = eData.matchOdds;
          if (matchOdds) {
            eData.matchOdds = {
              marketId: matchOdds.marketId,
              marketName: matchOdds.marketName,
              inplay: matchOdds.inplay,
              status: 'SUSPENDED',
              matchOddsRunners: matchOdds.matchOddsRunners,
            };
          } else {
            eData.matchOdds = {
              marketId: '',
              marketName: '',
              inplay: false,
              status: 'SUSPENDED',
              matchOddsRunners: [],
            };
          }
        }

        // Set EventData
        eData.homeTeam = homeTeam;
        eData.awayTeam = awayTeam;
        eData.eventSlug = eData.eventSlug
          ? eData.eventSlug
          : eData.eventName
              .toLowerCase()
              .replace(/[^a-z0-9]/g, ' ')
              .replace(/ +/g, ' ')
              .trim()
              .split(' ')
              .join('-');

        allEvents[eId][cId][eData.eventId] = eData;
      }
      if (action.payload.onRefresh) {
        let currentSelectedCompetition;
        let currentSelectedEvent;
        let currentSelectedEventType;

        try {
          currentSelectedEventType =
            state.selectedEventType.id === eId
              ? { ...state.selectedEventType }
              : {
                  id: eId,
                  name: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                    .name,
                  slug: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                    .slug,
                };
          currentSelectedCompetition =
            state.selectedCompetition.id === cId
              ? { ...state.selectedCompetition }
              : {
                  id: action.payload.eventData?.competitionId
                    ? action.payload.eventData.competitionId
                    : '',
                  name: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                    : '',
                  slug: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                        .toLocaleLowerCase()
                        .replace(/[^a-z0-9]/g, ' ')
                        .replace(/ +/g, ' ')
                        .trim()
                        .split(' ')
                        .join('-')
                    : '',
                };

          currentSelectedEvent =
            state.selectedEvent.id === action.payload.eventData?.eventId
              ? { ...state.selectedEvent }
              : {
                  id: action.payload.eventData?.eventId,
                  slug: action.payload.eventData?.eventName
                    .toLocaleLowerCase()
                    .replace(/[^a-z0-9]/g, ' ')
                    .replace(/ +/g, ' ')
                    .trim()
                    .split(' ')
                    .join('-'),
                  name: action.payload.eventData?.eventName,
                };
        } catch (_) {
          currentSelectedCompetition = { ...state.selectedCompetition };
          currentSelectedEvent = { ...state.selectedEvent };
          currentSelectedEventType = { ...state.selectedEventType };
        }
        return {
          ...state,
          events: allEvents,
          selectedEventType: currentSelectedEventType,
          selectedCompetition: currentSelectedCompetition,
          selectedEvent: currentSelectedEvent,
        };
      }

      return {
        ...state,
        events: allEvents,
      };
    }

    case UPDATE_SECONDARY_MATCH_ODDS: {
      const eventId: string = action.payload.eventId;
      const marketId: string = action.payload.marketId;

      const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };

      const matchOddsData = action.payload.matchOddsData;
      const runners: MatchOddsRunnerDTO[] = [];
      if (matchOddsData) {
        if (
          matchOddsData.matchOddsRunners &&
          matchOddsData.matchOddsRunners.length > 0
        ) {
          for (let e of matchOddsData.matchOddsRunners) {
            if (e) {
              if (
                eventId !== IPL.eventId ||
                (eventId === IPL.eventId &&
                  !IPL.blockedRunners.includes(e.selectionId))
              ) {
                const runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                runners.push({
                  selectionId: e.selectionId ? e.selectionId : e.SelectionId,
                  runnerName: runnerName,
                  backPrices: getMatchOddsSet(e.backPrices),
                  layPrices: getMatchOddsSet(e.layPrices),
                  status: e.status,
                });
              }
            }
          }
        }
        allSecMatchOddsMap[eventId + '-' + marketId] = {
          marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
          marketName: matchOddsData.marketName ? matchOddsData.marketName : '',
          inplay: matchOddsData.inplay ? matchOddsData.inplay : false,
          status: matchOddsData.status ? matchOddsData.status : '',
          matchOddsRunners: runners,
        };
      } else {
        let matchOdds = allSecMatchOddsMap[eventId + '-' + marketId];
        if (matchOdds) {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            inplay: matchOdds.inplay,
            status: 'SUSPENDED',
            matchOddsRunners: matchOdds.matchOddsRunners,
          };
        } else {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: '',
            marketName: '',
            inplay: false,
            status: 'SUSPENDED',
            matchOddsRunners: [],
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMatchOddsMap };
      secMatchOddsMap[eventId + '-' + marketId] =
        allSecMatchOddsMap[eventId + '-' + marketId];

      return {
        ...state,
        secondaryMatchOddsMap: secMatchOddsMap,
      };
    }

    case UPDATE_SECONDARY_MARKETS: {
      const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            bookmakers: [],
            fancyMarkets: [],
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData = action.payload.bookmakerOddsData;
        let bookMakerOdds: BookmakerDTO[] = [];
        if (bookMakerOddsData && bookMakerOddsData.length > 0) {
          for (let br of bookMakerOddsData) {
            let bmRUnners: BookmakerRunnerDTO[] = [];
            for (let b of br.bookmakerRunners) {
              bmRUnners.push({
                selectionId: b.selectionId ? b.selectionId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrice,
                backSize: b.backSize,
                layPrice: b.layPrice,
                laySize: b.laySize,
              });
            }
            // bmRUnners.sort((a, b) => {
            //   const aDesc = a.selectionId;
            //   const bDesc = b.selectionId;
            //   if (aDesc > bDesc) return 1;
            //   else if (aDesc < bDesc) return -1;
            //   else return 0;
            // });
            bookMakerOdds.push({
              marketId: br.marketId ? br.marketId : '-1',
              marketName: br.marketName ? br.marketName : '',
              bookmakerRunners: bmRUnners,
              status: br.status ? br.status : 'OPEN',
            });
          }
        } else if (marketsMap[eventId].bookmakers[0]) {
          bookMakerOdds = marketsMap[eventId].bookmakers;
          for (let bm of bookMakerOdds) {
            for (let br of bm.bookmakerRunners) {
              br.backPrice = '0';
              br.layPrice = '0';
            }
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[eventId].bookmakers = bookMakerOdds;

        // Set Fancy markets data
        const fancyOddsData = action.payload.sessionOddsData;
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            fancyOdds.push({
              selectionId: f.selectionId ? f.selectionId : '',
              runnerName: f.runnerName ? f.runnerName : '',
              gameStatus: f.gameStatus ? f.gameStatus : '',
              layPrice: f.layPrice ? f.layPrice : null,
              backPrice: f.backPrice ? f.backPrice : null,
              laySize: f.laySize ? f.laySize : null,
              backSize: f.backSize ? f.backSize : null,
            });
          }
        } else if (marketsMap[eventId].fancyMarkets) {
          fancyOdds = marketsMap[eventId].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.gameStatus = 'SUSPENDED';
          }
        }
        fancyOdds.sort((a, b) => {
          const aDesc = a.selectionId;
          const bDesc = b.selectionId;
          if (aDesc > bDesc) return -1;
          else if (aDesc < bDesc) return 1;
          else return 0;
        });
        marketsMap[eventId].fancyMarkets = fancyOdds;
      }

      return {
        ...state,
        secondaryMarketsMap: marketsMap,
      };
    }

    case UPDATE_SCORECARD: {
      return {
        ...state,
        scorecard: action.payload,
      };
    }

    case SET_EXCH_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }

    default:
      return state;
  }
};

export default exchangeSportsReducer;
