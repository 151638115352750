// import GQLClient from '../../graphql';
// import { gql } from '@apollo/client';
import {
  RESET_STATE,
  UPDATE_SCORECARD,
  SET_EVENT_TYPE,
  SET_COMPETITION,
  SET_EXCH_EVENT,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MATCH_ODDS,
} from './exchangeSportsActionTypes';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { AxiosResponse } from 'axios';
import API from '../../api';
import { EventDTO } from '../../models/common/EventDTO';
import { EXCH_SPORTS_MAP } from '../../constants/EventTypes';

// redux Actions

export const resetExchangeState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

// EventTypes
export const setEventType = (eventType: SelectedObj) => {
  return {
    type: SET_EVENT_TYPE,
    payload: eventType,
  };
};

// Competitions
export const setCompetition = (competition: SelectedObj) => {
  return {
    type: SET_COMPETITION,
    payload: competition,
  };
};

// Events
const fetchEventByCompetitionSuccess = (result) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: result,
  };
};

export const setExchEvent = (event: SelectedObj) => {
  return {
    type: SET_EXCH_EVENT,
    payload: event,
  };
};

export const fetchEvent = (
  sportId: string,
  competitionId: string,
  eventId: string
) => {
  return async (dispatch: Function) => {
    try {
      if (eventId !== '') {
        const response: AxiosResponse<any> = await API.get(
          `/common-feed/list-event/${sportId}/${competitionId}/${eventId}`
        );

        const eventData = response.data;

        const eData: EventDTO = {
          openDate: eventData.openDate,
          sportId: EXCH_SPORTS_MAP[eventData.sportId],
          competitionId: eventData.competitionId,
          competitionName: eventData.competitionName,
          eventId: eventData.eventId,
          eventName: eventData.eventName,
          marketId: eventData.marketId,
          providerName: eventData.providerName,
        };
        const payload = {
          eventData: eData,
          sportId: eData.sportId,
          competitionId: eData.competitionId,
          matchOddsData:
            eventData.market && eventData.market.matchOdds
              ? eventData.market.matchOdds.find(
                  (mo) => mo.marketName === 'Match Odds'
                )
              : null,
          onRefresh: true,
        };
        dispatch(fetchEventByCompetitionSuccess(payload));
        if (eData.sportId === '1' || eData.sportId === '4') {
          for (let mo of eventData.market.matchOdds) {
            if (mo.marketName !== 'Match Odds') {
              const secMOPayload = {
                eventId: eventData.eventId,
                marketId: mo.marketId,
                matchOddsData: mo,
              };
              dispatch(updateSecondaryMatchOdds(secMOPayload));
            }
          }
        }
        if (eData.sportId === '4') {
          const secMarketsPayload = {
            eventId: eventData.eventId,
            bookmakerOddsData:
              eventData.market && eventData.market.bookmakers
                ? eventData.market.bookmakers
                : null,
            sessionOddsData: eventData.market
              ? eventData.market.fancyMarkets
              : null,
          };
          dispatch(updateSecondaryMarkets(secMarketsPayload));
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const updateOddsfromWS = (payload) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: payload,
  };
};

export const updateSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_SECONDARY_MARKETS,
    payload: payload,
  };
};

export const updateSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};

export const updateEventScorecard = (payload) => {
  return {
    type: UPDATE_SCORECARD,
    payload: payload,
  };
};
