import { BRAND_DOMAIN } from '../constants/Branding';
const baseUrl = BRAND_DOMAIN.split('.')[1] + '.' + BRAND_DOMAIN.split('.')[2];

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-api.${baseUrl}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://dev-apilam.hypexone.com`,
  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/dev`,
  REACT_APP_GRAPHQL_API_URL: `https://dev-api.${baseUrl}/graphql/`,
  REACT_APP_WEBSOCKET_URL: `https://dev-api.hypexone.com/hypex-ws`,
  REACT_APP_AWS_WEBSOCKET_API_URL: `wss://apilam-ws.${baseUrl}`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://demo-api.${baseUrl}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://demo-apilam.hypexone.com`,
  REACT_APP_REST_LAMBDA_API_URL: `https://lw0jvywcv8.execute-api.eu-west-2.amazonaws.com/demo`,
  REACT_APP_GRAPHQL_API_URL: `https://demo-api.${baseUrl}.com/graphql/`,
  // REACT_APP_WEBSOCKET_URL: `https://demo-api.${baseUrl}.com/hypex-ws`,
  REACT_APP_WEBSOCKET_URL: `https://api.hypexone.com/hypex-ws`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${baseUrl}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://api-auth.hypexone.com`,
  REACT_APP_REST_LAMBDA_API_URL: `https://zbp5aapli1.execute-api.eu-west-2.amazonaws.com/prod`,
  REACT_APP_GRAPHQL_API_URL: `https://api.${baseUrl}/graphql/`,
  // REACT_APP_WEBSOCKET_URL: `https://api.kiaexch.com/hypex-ws`,
  REACT_APP_WEBSOCKET_URL: `https://api.hypexone.com/hypex-ws`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
