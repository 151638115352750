import API from '../../api';
import {
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_SUCCESS,
  CLEAR_SUCCESS_BETS,
  FETCH_OPEN_BETS_CACHE_SUCCESS,
} from './exchBetslipActionTypes';

export const removeExchangeBet = (index: number) => {
  return {
    type: REMOVE_EXCH_BET,
    payload: index,
  };
};

export const setExchangeBetStake = (
  index: number,
  amount: number,
  type: 'ADD' | 'SET'
) => {
  return {
    type: SET_EXCH_BET_STAKE,
    payload: {
      index,
      amount,
      type,
    },
  };
};

export const exchangeBetOddsChange = (index: number, odds: number) => {
  return {
    type: EXCH_BET_ODDS_CHANGE,
    payload: { index, odds },
  };
};

export const clearExchcngeBets = () => {
  return {
    type: CLEAR_EXCHANGE_BETS,
    payload: [],
  };
};

const fetchOpenBetsSuccess = (result) => {
  return {
    type: FETCH_OPEN_BETS_SUCCESS,
    payload: result,
  };
};

const fetchOpenBetsCacheSuccess = (result) => {
  return {
    type: FETCH_OPEN_BETS_CACHE_SUCCESS,
    payload: result,
  };
};

export const fetchOpenBets = (eventId: string, username?: string) => {
  return async (dispatch: Function) => {
    try {
      API.get('/admin/reports/open-bets', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          eventId: eventId,
          username: username ? username : '',
        },
      })
        .then((response) => {
          dispatch(fetchOpenBetsSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      dispatch(fetchOpenBetsSuccess([]));
    }
  };
};

export const fetchOpenBetsCache = (eventId: string, username?: string) => {
  return async (dispatch: Function) => {
    try {
      if (eventId) {
        API.get('/admin/reports/open-bets-cache', {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            eventId: eventId,
            username: username ? username : '',
          },
        })
          .then((response) => {
            dispatch(fetchOpenBetsCacheSuccess(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      dispatch(fetchOpenBetsCacheSuccess([]));
    }
  };
};

export const clearSuccessBets = (successIndices: number[]) => {
  return {
    type: CLEAR_SUCCESS_BETS,
    payload: successIndices,
  };
};
